@import './mixins.scss';
@import './colors.scss';

.default-button {
    @include flex();
    border: none;
    min-width: 240px;
    height: 40px;
    box-sizing: border-box;
    text-transform: uppercase;
    color: $secondary-font-color;
    letter-spacing: 0.03rem;
    transition: all ease-in-out 0.3s;
}

.default-button:hover {
    transform: translateY(-5px);
    transition: all ease-in-out 0.3s;
}

.red-button {
    background-color: $color-red;
}

.purple-button {
    background-color: $color-purple;
}

.blue-button {
    background-color: $color-blue;
}

.green-button {
    background-color: $color-green;
}