@import '../../styles/index.scss';

.footer-container {
    color: $secondary-font-color;
    border-top: 10px solid $divisor-background-color;
    background-color: $footer-primary-background-color;
    padding: 1rem 2rem;
}

.footer-responsive-title-container,
.footer-title-container {
    @include flex(row, space-between);
    border-bottom: 2px solid $footer-divisor-color;
    text-transform: uppercase;
    color: $footer-font-color;
    font-size: 1.3rem;
    padding: 1rem 2rem;

    li {
        width:23%;
    }
    li:nth-child(1) {
        width: 34%;
    }
    li:nth-child(2) {
        width: 34%;
    }
}

.footer-info-container {
    @include flex(row, space-between, flex-start);
    padding: 0rem 2rem;

    li {
        @include flex(row, flex-start);
    }
}

.footer-contact-container {
    width: 34%;
    padding-top: 0.5rem;

    li, 
    a {
        @include flex(row, flex-start, center);
        padding-bottom: 0.3rem;
        transition: all ease-in-out 0.3s;
    }

    a:hover {
        color: $divisor-background-color;
        transition: all ease-in-out 0.3s;
    }
}

.footer-bank-container {
    width: 34%;
}

.footer-network-container {
    width: 23%;
}

.footer-network-icons-container {
    @include flex(row, flex-start);
    width: 100%;

    li {
        margin-right: 1rem;
    }
}

.footer-contact-container,
.footer-bank-container,
.footer-network-container {
    .footer-responsive-title-container {
        display: none;
        margin-bottom: 1rem;
        padding: 1rem 0;
        width: 100%;
    }
}


.footer-icon {
    height: 15px;
    margin-right: 5px;
}

.footer-location-text {
    text-transform: uppercase;
    color: $footer-font-color;
}

.footer-contact-text {
    font-size: 12px;
    padding: 0.3rem 0rem;
}

.footer-bank-item {
    font-size: 14px;
    padding: 0.5rem 0rem;
}
.footer-bank-item:nth-child(3) {
    padding-bottom: 0;
 }

.footer-bank-item:nth-child(4) {
   padding-top: 0;
}

.footer-pinterest-icon {
    height: 2rem;
}

.footer-instagram-icon {
    height: 2.5rem;
}

.footer-youtube-icon {
    height: 2.2rem;
}

.footer-copyright {
    background-color: $footer-secondry-background-color;
    color: $secondary-font-color;
    width: 100%;
    padding: 1rem 2rem;
    box-sizing: border-box;

    p {
        @include flex(row, center);

        span {
            padding: 0px 0.5rem;
        }
    }
}

.footer-logo-container {
    @include flex();
}

.footer-logo {
    height: 100px;
}

//----------------------- RESPONSIVE DESIGN --------------

@media(max-width: 900px) {
    .footer-info-container {
        flex-direction: column;
    }

    .footer-contact-container,
    .footer-bank-container,
    .footer-network-container {
        width: 100%;

        .footer-responsive-title-container {
            display: block;
        }
    }

    .footer-title-container {
        display: none;
    }

    .footer-network-container {
       @include flex(column, flex-start, flex-start);
    }

    .footer-copyright {
        p {
            @include flex(column);
            text-align: center;
            font-size: 0.8rem;

            span:first-child {
                margin-bottom: 0.5rem;
            }

            span:nth-child(2) {
                display: none;
            }
        }
    }
}

@media(max-width: 500px) {
    .footer-info-container {
        padding: 0;
    }
}