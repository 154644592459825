@import '../../styles/index.scss';

.header {
    @include flex(column, center, flex-start);
    min-height: calc(100vh - 120px);
    position: relative;
    overflow: hidden;
    padding: 0 2rem;
}

.animate__animated.animate__zoomInLeft,
.animate__animated.animate__fadeInRight {
    --animate-duration: 2s;
}

  
.header-content-container {
    width: 50%;
    z-index: 1;
}

.header-title {
    font-family: 'Lemon Milk', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    text-transform: uppercase;
}

.header-text {
    padding: 1rem 0rem;
}

.header-button-container {
    @include flex(row, space-evenly);
}

.header-background-image-container {
    height: 100%;
    position: absolute;
    bottom: -180px;
    right: 5%;
    
}

.header-background-image {
    height: 100%;
}


//----------------------- RESPONSIVE DESIGN --------------

@media(max-width: 1600px) {
    .header-background-image-container {
        height: 90%;
    }
}

@media(max-width: 1400px) {
    .header-background-image-container {
        width: 100%;
        height: 80%;
        right: -30%;
    }
}

@media(max-width: 1200px) {
    .header-background-image-container {
        right: -20%;
    }
}

@media(max-width: 1000px) {
    .header-background-image-container {
        right: -12%;
    }
}

@media(max-width: 900px) {
    .header-button-container {
        justify-content: flex-start;
        padding-left: calc(40% - 100px);

        .default-button {
            width: 40%;
        }
    }

    .header-content-container {
        width: 70%;

        .header-title, 
        .header-text {
            width: 85%;
        }
    }

    .header-background-image-container {
        height: 60%;
        right: 0%;
    }
}

@media(max-width: 600px) {
    .header-content-container {
        width: 90%;

        .header-title, 
        .header-text {
            width: 70%;
        }
    }

    .header-background-image-container {
        right: 20%;
    }
}


@media(max-width: 550px) {
    
    .header-content-container {
        width: 100%;

        .header-title {
            width: 95%;
        }

        .header-text {
            width: 60%;
        }
    }

    .header-button-container {
        justify-content: center;
        padding-left: 0px;
        .default-button {
            width: 80%;
        }
    }
    
}


@media(max-width: 400px) {
    .header {
        justify-content: flex-start;
        padding-top: 2rem;
        min-height: calc(100vh - 150px);
    }
    .header-content-container {
        width: 100%;

        .header-title, 
        .header-text {
            width: 100%;
        }
    }

    .header-background-image-container {
        right: 20%;
        bottom: -100px;
    }
}