
@import './globals.scss';
@import './colors.scss';
@import './mixins.scss';
@import './buttons.scss';

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $primary-background-color;
  font-size: 1rem;
}
