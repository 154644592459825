@import '../../styles/index.scss';

.divisor-section {
    background-color: $divisor-background-color;
    min-height: 120px;
}

.divisor-section.recommendations-section {
    @include flex(column);
    padding: 2rem;
    height: auto;
}

.recommendations-description {
    @include flex(column);
}

.recommendations-text  {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: bold;
    text-align: center;
    padding: 1rem;
    width: 50%;
}

//----------------- RECOMMENDATIONS RESPONSIVE DESIGN --------------

@media(max-width: 1000px) {
    .recommendations-text  {
       width: 90%;        
    }
}

@media(max-width: 600px) {
    .divisor-section.recommendations-section {
        padding: 0;
        height: 2rem;
    }

    .recommendations-text  {
        display: none
    }
}

// -------------------- CARDS SECTION ----------------------

.cards-section {
    @include flex(row, space-between);
    padding: 2rem;
    background-color: $cards-background-color;
}

.card-container {
    @include flex(column, space-between, center);
    width: 25%;
    padding: 0.5rem 2rem;
    padding-top: 0;
    box-shadow: 0px 1px 4px -1px rgba(0,0,0, 0.3);
    min-height: 722px;
}

.first-card-top-border {
    border-top: 7px solid $card-primary-color;
}

.second-card-top-border {
    border-top: 7px solid $card-secondary-color;
}

.third-card-top-border {
    border-top: 7px solid $card-tertiary-color;
}

.card-image-container {
    @include flex();
    min-height: 320px;
}

.card-image {
    width: 100%;
}

.card-title {
    text-align: center;
}

.card-text {
    padding: 0.5rem 0rem;
    text-align: left;
    width: 100%;
}

.card-list {
    width: 100%;
}

.card-button {
    width: 100%;
    margin-top: 0.5rem;
}

.first-card-button-color {
    background-color: $card-primary-color;
}

.second-card-button-color {
    background-color: $card-secondary-color;
}

.third-card-button-color {
    background-color: $card-tertiary-color;
}

//----------------- CARDS RESPONSIVE DESIGN --------------

@media(max-width: 1500px) {
    .card-container {
        min-height: 625px;
    }
}

@media(max-width: 1200px) {
    .card-image-container {
        min-height: 270px;
    }
}

@media(max-width: 1000px) {
    .card-image-container {
        min-height: 230px;
    }
}

@media(max-width: 900px) {
    .cards-section  {
        flex-direction: column;
    }

    .card-container {
        width: 80%;
        margin-bottom: 2rem;
    }

    .card-container:last-child {
        width: 80%;
        margin-bottom: 0;
    }
}

// -------------------- BOY SECTION ----------------------

.boy-section-container {
    background-color: $terciary-background-color;
    min-height: 100vh; 
    position: relative;
    overflow: hidden;
}

.boy-hindu-descriptions-container {
    @include flex(row, space-around);
    gap: 1rem;
    padding: 0rem 2rem;
    color: $secondary-font-color;
    font-size: 14px;
    text-align: center;
    letter-spacing: 1px;
    font-weight: lighter;
    height: 150px;
    box-sizing: border-box;
}

.boy-hindu-description {
    @include flex(column);
    max-width: 23%;
    margin: 1rem 0;
}

.boy-hindu-image {
    height: 70px;
    margin-bottom: 0.5rem;
}


.boy-background-image-container {
    bottom: -310px;
    right: 45%;
}

.boy-background-image {
    height: 75%;
}


.home-title {
    text-transform: uppercase;
}

.home-first-subtitle {
    text-align: left;
    padding: 0.5rem 0rem;
}

.home-second-subtitle {
    font-weight: bold;
    padding-bottom: 2rem;
}

.home-button {
    padding: 0rem 0.5rem;
    align-self: center;
}

//----------------- BOY RESPONSIVE DESIGN --------------

@media(max-width: 1100px) {
    .background-image-container.boy-background-image-container {
        right: 35%;
        height: 90%;
    }
}

@media(max-width: 800px) {
    .boy-hindu-descriptions-container  {
       .boy-hindu-description {
            display: none;
            height: 120px;
       }      
    }

    .boy-text-container {
        justify-content: flex-start;
        padding-left: 1rem;
    }

    .background-image-container.boy-background-image-container {
        right: -50px;
        height: 75%;
    }

    .boy-text-title-container {
        align-items: flex-start;
        width: 100%;

        .home-title,
        .home-first-subtitle,
        .home-second-subtitle {
            width: 75%;
       }
    }
}

@media(max-width: 700px) {
    .background-image-container.boy-background-image-container {
        height: 68%;
    }
}

@media(max-width: 550px) {
    .background-image-container.boy-background-image-container {
        height: 68%;
        right: -33%
    }

    .boy-text-title-container {
       .home-title {
            width: 85%;
        }

       .home-button {
        width: 80%;
        }
    }
}

@media(max-width: 450px) {
   .boy-section-container {
    min-height: fit-content;
    padding-bottom: 2rem;
   }

   .background-image-container.boy-background-image-container {
    width: 65%;
    bottom: -25%;
    height: 90%;
    right: 10%;
 }
}

@media(max-width: 400px) {
    .boy-background-image-container {
       width: 100%;
       bottom: -100px;
       height: 50%;
       right: -40%;
    }
    .boy-text-container {
        padding-top: 2rem;
        min-height: auto;
    }

    .boy-text-title-container {
        .home-button {
            width: 105%;
        }
     }
}

// -------------------- GIRL SECTION ----------------------
.girl-section-container {
    min-height: 100vh; 
    position: relative;
    overflow: hidden;
}

.girl-background-image-container {
    width: 100%;
    position: absolute;
    bottom: -266px;
    right: -40%;
}

.girl-background-image {
    height: 80%;
}

.girl-text-container {
    @include flex(row, flex-start);
    min-height: calc(100vh - 150px);
    font-family: 'Lemon Milk', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    position: relative;
    z-index: 1;
    padding-left: 2rem;
}

.girl-text-title-container {
    @include flex(column, center, flex-start);
    width: 50%;
}
//----------------- GIRL RESPONSIVE DESIGN --------------

@media(max-width: 1300px) {
    .girl-background-image-container {
        right: -30%;
    }
}

@media(max-width: 1100px) {
    .girl-background-image-container {
        height: 90%;
        right: -42%;
    }
}


@media(max-width: 850px) {
    .girl-background-image-container {
        height: 68%;
    }

    .girl-text-title-container {
        align-items: flex-start;
        width: 100%;

        .home-title,
        .home-first-subtitle,
        .home-second-subtitle {
            width: 70%;
       }
    }
}

@media(max-width: 650px) {
    .girl-background-image-container {
        right: -25%;
    }

    .girl-text-title-container {
        .home-title {
            width: 80%;
        }

        .home-first-subtitle,
        .home-second-subtitle {
            width: 65%;
        }
    }
}

@media(max-width: 550px) {
    .girl-section-container {
        min-height: fit-content;
    }

    .girl-text-container {
        padding: 3rem 2rem;
        min-height: fit-content;
    }

    .girl-text-title-container {
        .home-title {
            width: 75%;
        }

        .home-first-subtitle,
        .home-second-subtitle {
            width: 60%;
        }
    }

    .girl-background-image-container {
        height: 95%;
        bottom: -120px;
        right: -39%;
    }

    .home-button {
        width: 80%;
    }
}

@media(max-width: 400px) {
    .girl-background-image-container {
       width: 100%;
       bottom: -100px;
       height: 70%;
       right: -24%;
    }
    .girl-text-container {
        padding-top: 2rem;
        min-height: auto;
    }

    .girl-text-title-container {
        .home-button {
            width: 105%;
        }
     }
}