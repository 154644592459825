@import './colors.scss';
@import './mixins.scss';

$nav-height: 80px;

.purple-text {
    color: $color-purple;
}

.white-text {
    color: $color-white;
}

a, 
button {
    cursor: pointer;
}

a,
a:hover,
a:active,
a:visited {
    color: $color-white;
    text-decoration: none;
}

ul {
    list-style: none;
}


// --------------------- RIGHT TEXT CONTAINER -------------------------
.right-text-container {
    @include flex(row, flex-end);
    min-height: calc(100vh - 150px);
    font-family: 'Lemon Milk', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    z-index: 1;
    position: relative;
    padding-right: 2rem;
}

.right-text-title-container {
    @include flex(column, flex-start, flex-start);
    width: 50%;
}

.right-title {
    text-transform: uppercase;
}

.right-first-subtitle {
    text-align: left;
    padding: 0.5rem 0rem;
}

.right-second-subtitle {
    font-weight: bold;
    padding-bottom: 2rem;
    text-align: left;
}

.right-button-container {
    align-self: center;
}

.right-button {
    padding: 0rem 0.5rem;
}

.background-image-container {
    height: 100%;
    position: absolute;
}


// --------------------- RIGHT TEXT CONTAINER RESPONSIVE -------------------------

@media(max-width: 800px) {
    .right-text-container {
        justify-content: flex-start;
        padding-left: 1rem;
    }

    .right-text-title-container {
        align-items: flex-start;
        width: 100%;

        .right-title,
        .right-first-subtitle,
        .right-second-subtitle {
            width: 75%;
       }
    }
}


@media(max-width: 550px) {
    .right-text-title-container {
        .right-title {
             width: 85%;
         }
 
        .right-button {
         width: 80%;
         }
     }
}

@media(max-width: 450px) {
    .right-text-container {
        padding-top: 2rem;
        min-height: auto;
        padding-right: 0px;
        min-width: 360px;
    }

    .right-text-title-container {
        width: 85%;

        .right-button {
            width: 105%;
        }
     }     
}

// -------------------- FINAL SECTION ----------------------

.final-section {
    text-align: center;
}

.final-text-container {
    font-family: 'Lemon Milk', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    position: relative;
    z-index: 1;
    padding: 3rem 2rem;
    width: 80%;
    margin: auto;
}

.final-title {
    text-transform: uppercase;
    font-weight: bold;
}

.final-subtitle {
    padding: 1rem;
    padding-bottom: 0rem;
}

.default-button.final-button {
    padding: 0rem 1rem;
    margin: 1rem auto 0rem;
    width: 240px;
}

//----------------- FINAL RESPONSIVE DESIGN --------------

@media(max-width: 500px) {

    .default-button.final-button {
        width: 80%;
    }
}