@import '../../styles/index.scss';

// ----------------- MEDITATION SECTION ------------
.meditation-section-container {
    background-color: $terciary-background-color;
    position: relative;
    overflow: hidden;
    height: calc(100vh - $nav-height);
}

.meditation-triangle-background-container {
    width: 100%;
    height:  100%;
}

.meditation-triangle-background-container::before {
    content: "";
    position: absolute;
    border-left: 80vh solid $divisor-background-color;
    border-right: 75vh solid transparent;
    border-bottom: 100vw solid transparent;
    left: 10vw;
    top: 0;
}

.meditation-title {
    font-size: 7rem;
    text-align: center;
    text-transform: uppercase;
    padding-top: 4rem;
    letter-spacing: 1rem;
    font-weight: 900;
    position: relative;
    z-index: 1;
}

.meditation-background-image-container {
    bottom: -70px;
    left: 17%;
    height: 80%;
}

// ----------------- MEDITATION RESPONSIVE  ------------

@media(max-width: 1400px) {
    .meditation-triangle-background-container::before {
        left: 6vw;
    }

    .meditation-background-image-container {
        left: 10%;
    }
}

@media(max-width: 1100px) {
    .meditation-triangle-background-container::before {
        border-left: 70vh solid $divisor-background-color;
        left: 8%;
    }

    .meditation-background-image-container {
        left: 5%;
    }

    .meditation-title {
        font-size: 5rem;
    }
}


@media(max-width: 950px) {
    .meditation-section-container {
        .right-text-container {
            justify-content: flex-end;
        }
    }

    .meditation-triangle-background-container::before {
        border-bottom: 115vw solid transparent;
        left: 8%;
    }

    .meditation-background-image-container {
        height: 65%;
    }

    .meditation-title {
        font-size: 4rem;
    }
}

@media(max-width: 800px) {
    .meditation-section-container {
        .right-text-title-container {
            max-width: 50%;
        }

        .right-title,
        .right-first-subtitle,
        .right-second-subtitle {
            width: 100%;
        }
    }

    .meditation-triangle-background-container::before {
        border-bottom: 170vh solid transparent;
        border-left: 50vh solid $divisor-background-color;
        left: 0%;
    }

    .meditation-background-image-container {
        height: 570px;
        left: -3rem;
    }

    .meditation-title {
        font-size: 3rem;
        letter-spacing: 0.5rem;
    }
}



@media(max-width: 600px) {
    .meditation-section-container {
        .right-text-container {
            padding-right: 1rem;
        }
        .right-text-title-container {
            max-width: 300px;
        }
    }

    .meditation-triangle-background-container::before {
        border-left: 30vh solid $divisor-background-color;
    }

    .meditation-background-image-container {
        height: 55%;
        left: -3rem;
        bottom: -125px;
    }

    .meditation-title {
        font-size: 2.5rem;
    }
}

@media(max-width: 400px) {
    .meditation-section-container {
        .right-text-container {
            justify-content: center;
            padding-right: 0;
        }
    }

    .meditation-background-image-container {
        height: 55%;
        left: -3rem;
        bottom: -125px;
    }

    .meditation-title {
        font-size: 2.5rem;
    }
}

// ----------------- MEDITATION POWER SECTION ------------

.meditation-power-section-container {
    padding: 2rem 0rem;
}

.meditation-faq-title,
.meditation-power-title {
    text-align: center;
    font-size: 2rem;
}

.meditation-power-list-container {
    width: fit-content;
    margin: auto;
    padding: 2rem;

    li {
        @include flex();
        width: fit-content;
    }
}

.meditation-power-list-text {
    font-size: 2rem;
    width: fit-content;
    padding-left: 1rem;
}

.meditation-faq-title {
    color: $footer-font-color;
    padding-bottom: 2rem; 
}

.meditation-faq-table-container {
    border: 2px solid $divisor-background-color;
    margin: auto;
    width: 80%;
}

.meditation-faq-container {
    border: 2px solid $divisor-background-color;
    transition: all ease-in-out 0.3s;
    overflow: hidden;
    max-height: calc(1.5rem + 34px);
}

.meditation-faq-button {
    @include flex();
    border: none;
    background-color: transparent;
    box-sizing: border-box;
    font-size: 1.5rem;
    padding: 1rem;
    font-weight: 700;
    text-align: left;

    p {
        padding-left: 0.5rem;
    }

    img {
        transition: all ease-in-out 0.3s;
    }
}

.meditation-power-answer-container {
    padding-top: 1rem;
    p {
        font-size: 1.5rem;
        padding: 0rem calc(1.5rem + 30px) 1rem;
        font-weight: lighter;
    }
}

.open-card {
    max-height: 270px;
    transition: all ease-in-out 0.3s;

    img {
        transform: rotate(90deg);
        transition: all ease-in-out 0.3s;
    }
}

// ----------------- MEDITATION POWER RESPONSIVE ------------


@media(max-width: 800px) {
    .meditation-faq-title,
    .meditation-power-title {
        text-align: center;
        font-size: 1.5rem;
    }

    .meditation-power-list-container {

        li {
            span,
            img {
                height: 1.5rem !important;
            }
        }
      
    }

    .meditation-power-list-text {
        font-size: 1.5rem;
    }

    .meditation-faq-container {
        max-height: calc(1rem + 30px);
    }

    .meditation-power-answer-container p,
    .meditation-faq-button {
        font-size: 1rem;
    }

    .meditation-faq-button {
        span,
        img {
            height: 1rem !important;
        }
    }

    .open-card.meditation-faq-container {
        max-height: 225px;
    }
}

@media(max-width: 550px) {
    .meditation-faq-table-container {
        width: 95%;
    }
}

@media(max-width: 450px) {
    .meditation-faq-table-container {
        width: 100%;
        border-left: none;
        border-right: none;
    }

    .meditation-faq-container {
        border-left: none;
        border-right: none;
    }

    .meditation-faq-container {
        max-height: calc(1rem + 45px);
    }

    .meditation-faq-button {
        height: calc(1rem + 45px);
    }

    .open-card.meditation-faq-container {
        max-height: 280px;
    }
}