// ----------- FONT -----------
$secondary-font-color: #fff;
$terciary-font-color: #622ae7;
$footer-font-color: #b9a650;
$color-white: #fff;

// ------------ BACKGROUNDS ---------------
$primary-background-color: #f7f7f6;
$secondary-background-color: #fff;
$terciary-background-color: #e4e8e9;
$divisor-background-color: #b9a650;
$footer-primary-background-color: #3a3a3a;
$footer-secondry-background-color: #161616;

// ------------ CARD COLORS -----------------
$card-primary-color: #68f171;
$card-secondary-color: #4e6bd7;
$card-tertiary-color: #f02340;
$cards-background-color: #fff;

// ------------ BUTTON COLORS  ---------------
$color-red: #a7101b;
$color-purple: #622a7e;
$color-blue: #4e6bd7;
$color-green: #68f171;

// ------------ OTHER COLORS  ---------------
$footer-divisor-color: #949494;