@import '../../styles/index.scss';

.construction-page {
    @include flex();
    min-height: calc(100vh - 80px);
    width: 100%;
    overflow: hidden;
}

.construction-main-container {
    @include flex(column);
    background-color: $secondary-background-color;
    padding: 2rem;
    box-sizing: border-box;
    width: 50%;
    min-width: 980px;
    overflow: hidden;
}

.construction-image {
    width: 100%;
}

.construction-title {
    font-size: 3rem;
    padding-bottom: 3rem;
    text-align: center;
}

.construction-text {
    padding-top: 1.5rem;
    font-size: 1.3rem;
    text-align: center;
}

// ------------------- RESPONSIVE --------------------

@media(max-width: 1000px) {
    .construction-main-container {
        padding: 1rem;
        min-width: 600px;
        width: 90%;
    }
}


@media(max-width: 800px) {
    .construction-main-container {
        width: 98%;
    }

    .construction-title {
        font-size: 2rem;
    }

    .construction-text {
        font-size: 1rem;
    }
}

@media(max-width: 600px) {
    .construction-main-container {
        min-width: 400px;
    }

    .construction-image {
        min-width: 400px;
    }

    .construction-title {
        font-size: 2rem;
    }

    .construction-text {
        font-size: 1rem;
    }
}