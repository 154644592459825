@import '../../styles/index.scss';

.nav {
    position: relative;
}

.nav-container {
    @include flex(row, space-between);
    background-color: $secondary-background-color;
    padding: 0 2rem;
    position: relative;
    z-index: 20;
}   

.nav-logo-container {
    height: $nav-height;
    overflow: hidden;
}

.nav-logo {
    max-width: 150px;
    object-fit: cover;
    object-position: 0px -32px;
}

.nav-list {
    @include flex(row, space-around);
    width: 80%;
}

.nav-list-item {
    text-transform: uppercase;
    font-weight: bold;
    transition: all ease-in-out 0.3s;
    width: 20%;

    .nav-list-item-link,
    .nav-list-item-link-responsive {
        @include flex();
        height: 80px;
        color: black;
    }

}

.nav-list-item:hover,
.nav-list-item-link-responsive:hover {
    background-color: $terciary-background-color;
    transition: all ease-in-out 0.3s;
    
    .nav-list-item-link {
        color: black;
    }
}

.nav-list-item-link.active {
    background-color: $terciary-background-color;
    transition: all ease-in-out 0.3s;
}

.nav-list-item-language {
    @include flex();
    position: relative;
    width: 20%;

    & > button {
        height: 80px;
        transition: all ease-in-out 0.3s;
    }
}

.nav-item-button {
    @include flex(row);
    border: none;
    background-color: transparent;
    font-size: 1rem;
    width: 100%;
    margin-left: 2rem;
    transition: all ease-in-out 0.3s;
    color: black;
}

.nav-item-image {
    width: 20px;
    margin-left: 0.5rem;
}

.nav-language-sublist-close {
    visibility: hidden;
    opacity: 0;
    top: 22px;
    transition: all ease-out 0.3s;
}

.nav-language-sublist-open {
    visibility: visible;
    opacity: 1;
    top: 80px;
    transition: all ease-out 0.2s;
}

.nav-sublist-item {
    margin: 0.3rem 0rem;
    padding: 0rem 1rem;
    min-width: 150px;
    transition: all ease-out 0.3s;
}

.nav-sublist-item:hover {
    background-color: $terciary-background-color;
    transition: all ease-out 0.3s;
}

.nav-item-language-sublist {
    background-color: $secondary-background-color;
    border-radius: 5px;
    padding: 0.5rem 0rem;
    position: absolute;
    min-width: 140px;
    right: -15px;
    z-index: 13;
}

.nav-item-button-languange {
    text-transform: uppercase;
    font-weight: bold;
    min-height: 45px;
    margin-left: 0rem;
    justify-content: flex-start;
    color: black;

    .nav-item-image {
        margin-left: 0rem;
        margin-right: 0.5rem;
    }
}

//----------------------- RESPONSIVE DESIGN --------------

.nav-list-item-responsive-menu {
    display: none;
    z-index: 20;
}

.nav-list-item.nav-list-item-responsive {
    cursor: pointer;
    @include flex(row, flex-start);
    width: 100%;
    a {
        height: auto;
    }
}

.nav-list-responsive {
    display: none;
    width: 100%;
    position: absolute;
    background-color: $secondary-background-color;
    box-sizing: border-box;
    bottom: 80px;
    left: 0;
    z-index: 2;
    overflow: hidden;
}

.nav-list-responsive-closed {
    visibility: hidden;
    transition: all ease-out 0.3s;
}

.nav-list-responsive-opened {
    background-color: $secondary-background-color;
    visibility: visible;
    bottom: -350px;
    transition: all ease-out 0.3s;
    padding-left: 0.5rem;
    padding-top: 15px;
}

.nav-list-item-responsive {
    padding: 1rem
}

.nav-menu-image {
    margin: 0;
}

.nav-item-button.nav-item-button-responsive {
    justify-content: flex-start;
    padding-bottom: 0.5rem;
    margin-left: 0;
}

.nav-item-button.nav-item-button-responsive:hover {
   background-color: $secondary-background-color;
}

.nav-sublist-item-responsive {
    transition: all ease-in-out 0.3s;
    padding-left: 2rem;

    .nav-item-button-languange {
        min-height: 35px;
    }
}

.nav-sublist-item-responsive:hover {
    background-color: $terciary-background-color;
    transition: all ease-in-out 0.3s;
}

@media(max-width: 950px) {
    
    .nav-list-responsive {
        display: block;
    }

    .nav-list-item-responsive-menu {
        display: block;
    }

    .nav-list-desktop {
        display: none;
    }
}